import Layout from 'components/layout'
import '../styles/globals.css'
import 'react-toastify/dist/ReactToastify.css'
import HotjarTracking from 'components/hotjar'
import GoogleAnalytics from 'components/google-analytics'

export default function App({ Component, pageProps }) {
  return (
    <Layout>
      <Component {...pageProps} />
      <GoogleAnalytics GA_MEASUREMENT_ID={process.env.NEXT_PUBLIC_GA_ID} />
      <HotjarTracking hotjarId={process.env.NEXT_PUBLIC_HOTJAR_SITE_ID} />
    </Layout>
  )
}
